import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { getBlogsById } from "../../Services/api";
import "./Blogs.css";
import BlogsAds from "./Ads";

function BlogDetails() {
  const sideData = ["Other Blogs", "Sponsored Posts", "Ads"];
  const { id } = useParams(); // Fetch the blog ID from the URL

  const [data, setData] = useState(null); // Blog data state

  const getData = async (id) => {
    try {
      const resp = await getBlogsById(id); // Fetch the blog details by ID
      if (resp.data.success === 1 && resp.data.data.length > 0) {
        setData(resp.data.data[0]); // Set the first blog in the response
      }
    } catch (error) {
      console.log("Error fetching blog details: ", error);
    }
  };

  useEffect(() => {
    getData(id); // Fetch blog details when component mounts or when id changes
  }, [id]);

  return (
    <Container fluid className="blog-details-container">
      <div className="row">
        {/* Blog Content Section */}
        <div className="col-lg-9 col-md-8 col-sm-12 mb-4">
          {data ? (
            <div className="blog-content">
              {/* Blog Image */}
              <div className="text-center">
                <img
                  src={data.image}
                  alt={data.title}
                  className="blog-image rounded"
                  style={{
                    height: "300px",
                  }}
                />
              </div>
              {/* Blog Title */}
              <h2 className="blog-title mb-4">{data.title}</h2>
              {/* Blog Description */}
              <p className="blog-description mb-3">
                <strong>Description:</strong> {data.description}
              </p>
              {/* Blog Author */}
              <p className="blog-author mb-4">
                <strong>Author:</strong> {data.author}
              </p>
            </div>
          ) : (
            <div className="loading text-center">Loading...</div> // Centered loading message
          )}
        </div>

        {/* Sidebar Section */}
        <BlogsAds />
      </div>
    </Container>
  );
}

export default BlogDetails;
