import { Container } from "react-bootstrap";
import "./Events.css";
import { useEffect, useState } from "react";
import { getEventsById } from "../../Services/api";
import { useParams } from "react-router-dom";
import BlogsAds from "../Blogs/Ads";
function EventDetails() {
  const sideData = ["Other Blogs", "Sponsored Posts", "Ads"];
  const { id } = useParams(); // Fetch the blog ID from the URL

  const [data, setData] = useState(null); // Blog data state

  const getData = async (id) => {
    try {
      const resp = await getEventsById(id); // Fetch the blog details by ID
      if (resp.data.success === 1 && resp.data.data.length > 0) {
        setData(resp.data.data[0]); // Set the first blog in the response
      }
    } catch (error) {
      console.log("Error fetching blog details: ", error);
    }
  };

  useEffect(() => {
    getData(id); // Fetch blog details when component mounts or when id changes
  }, [id]);
  return (
    <Container fluid style={{ minHeight: "100vh" }}>
      <div className="row px-2">
        <div className="col-9 px-4">
          <div className="row">
            <div className="col-12">
              <img
                src={data?.image}
                alt="image"
                width="100%"
                className="img-fluid"
                style={{ height: "400px", objectFit: "cover" }}
              />
            </div>
            <div className="col-12">
              <div className="row d-flex title mb-2">{data?.title}</div>
              <p>{data?.description}</p>
            </div>
          </div>
        </div>
        <BlogsAds />
      </div>
    </Container>
  );
}

export default EventDetails;
